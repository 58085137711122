import { withRouter } from 'next/router';
import Link from 'next/link';
import { cloneElement } from 'react';
import PropTypes from 'prop-types';

const ActiveLink = ({
  router,
  children,
  href,
  activeClassName = 'current',
  partiallyActive = false,
  noLink,
  ...props
}) => {
  if (!router || !children || !href) return null;
  let className = children.props.className || '';

  const isActive = router.pathname === href;
  const isPartiallyActive =
    router.pathname !== '/' &&
    (href.pathname || href).indexOf(router.pathname.split('/')[1]) > -1;

  if (activeClassName && (isActive || (partiallyActive && isPartiallyActive))) {
    className = `${className} ${activeClassName}`.trim();
  }

  if (noLink) return cloneElement(children, { className });

  return (
    <Link {...props} href={href} legacyBehavior>
      {cloneElement(children, { className })}
    </Link>
  );
};

ActiveLink.propTypes = {
  router: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  href: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.bool,
  noLink: PropTypes.bool,
};

export default withRouter(ActiveLink);
