import PropTypes from 'prop-types';
import Button from 'components/Button';
import { useTranslation } from 'next-i18next';

export default function HeaderLink({
  linkType,
  extraClasses,
  onClick,
  IconLeft,
  href,
  test,
  rel,
}) {
  const { t } = useTranslation();
  const label = t(`header.${linkType}.label`);
  const Tag = href ? 'a' : 'button';

  return (
    <Button
      Tag={Tag}
      href={href}
      IconLeft={IconLeft}
      extraClasses={`c-button--transparent c-header__link ${
        extraClasses || ''
      }`.trim()}
      label={label}
      onClick={onClick}
      rel={rel}
      test={test}
    />
  );
}

HeaderLink.propTypes = {
  linkType: PropTypes.string.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  test: PropTypes.string,
  extraClasses: PropTypes.string,
  IconLeft: PropTypes.func,
};
