import Eyecon from 'svg/icon-eye.svg';
import Company from 'svg/icon-company.svg';
import Location from 'svg/icon-pin.svg';
import Hours from 'svg/icon-clock.svg';
import RangeIcon from 'svg/arrow-right.svg';
import Category from 'svg/icon-files.svg';
import EducationLevel from 'svg/icon-briefcase.svg';
import DisplayDate from 'components/DisplayDate';
import Button from 'components/Button';
import Properties from 'components/Properties';
import { useTranslation } from 'next-i18next';
import DoubleChevronRight from 'svg/double-chevron-right.svg';
import PropTypes from 'prop-types';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useMemo } from 'react';

const Card = ({
  Tag = 'div',
  extraClasses,
  TitleTag = 'h3',
  title,
  href,
  views,
  description,
  htmlDescription,
  company,
  location,
  hours,
  cta,
  disabled,
  ctaExtraClasses,
  onClick,
  createdAt,
  createdAtPrefix,
  children,
  test,
  dataId,
  note,
  image,
  imageWidth,
  imageHeight,
  hidden,
  showImagePlaceholder,
  containerRef,
  range,
  category,
  educationLevel,
}) => {
  const { t } = useTranslation();
  const hasProperty =
    !!company ||
    !!location ||
    !!hours ||
    !!cta ||
    !!note ||
    !!range ||
    !!category ||
    !!educationLevel;

  const titleContent = useMemo(() => {
    if (href) {
      return (
        <Link
          href={href}
          tabIndex={0}
          onClick={onClick}
          className="u-umbrella-link c-card__link"
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}

          {title}
        </Link>
      );
    }

    if (onClick) {
      return (
        <button
          type="button"
          className="u-umbrella-link c-card__link"
          onClick={onClick}
        >
          {title}
        </button>
      );
    }

    return title;
  }, [href, onClick, title]);

  return (
    <Tag
      className={`c-card${href ? ' c-card--has-link' : ''} ${
        extraClasses || ''
      }`.trim()}
      data-cy={test}
      data-id={dataId}
      ref={containerRef}
      hidden={hidden}
    >
      {/* Title and view container */}
      {!!title && (
        <div className="c-card__title-views">
          <TitleTag className="c-card__title">{titleContent}</TitleTag>
          {typeof views === 'number' && (
            <div className="c-card__views">
              <Eyecon className="c-card__views-icon" />
              {t('card.views', { views })}
            </div>
          )}
        </div>
      )}
      {!!createdAt && !!createdAtPrefix && (
        <DisplayDate
          date={createdAt}
          extraClasses="u-text-gray-500 u-text-small u-margin-top-tiny"
          prefix={createdAtPrefix}
        />
      )}
      {/* Description 
        using stripped string in dangerouslySetInnerHTML to support hmtl entities
      */}
      {!!description && (
        <p
          className="c-card__description"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: description
              .replace(/(<([^>]+)>)/gi, '\n')
              .trim()
              .split('\n')[0],
          }}
        />
      )}
      {/* HTML description */}
      {!!htmlDescription && (
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{ __html: htmlDescription }} />
      )}
      {/* Image */}
      {(!!image || showImagePlaceholder) && (
        <div className="c-card__image-container">
          {!!image && (!imageWidth || !imageHeight) && (
            <Image src={image} alt="" layout="fill" objectFit="contain" />
          )}
          {!!image && imageWidth && imageHeight && (
            <Image
              src={image}
              alt=""
              layout="fixed"
              objectFit="contain"
              width={imageWidth}
              height={imageHeight}
            />
          )}
        </div>
      )}
      {/* Properties (company, location, hours etc.) */}
      {hasProperty && (
        <div className="c-card__properties-cta-container">
          <Properties
            properties={[
              {
                Icon: Company,
                label: t('property.company'),
                value: company,
              },
              {
                Icon: Location,
                label: t('property.location'),
                value: location,
              },
              {
                Icon: Hours,
                label: t('property.hours'),
                value: hours,
                fullWidth: true,
              },
              {
                Icon: RangeIcon,
                label: t('property.range'),
                value: range,
              },
              {
                Icon: Category,
                label: t('property.category'),
                value: category,
              },
              {
                Icon: EducationLevel,
                label: t('property.education'),
                value: educationLevel,
              },
            ]}
          />
          {(!!onClick || !!href) && !!cta && (
            <Button
              label={cta}
              Tag="span"
              extraClasses={`c-button--small c-button--link c-card__cta ${
                ctaExtraClasses || ''
              }`.trim()}
              Icon={DoubleChevronRight}
              disabled={disabled}
            />
          )}
          {!!note && <div className="c-card__note">{note}</div>}
        </div>
      )}
      {children || ''}
    </Tag>
  );
};

Card.propTypes = {
  Tag: PropTypes.string,
  extraClasses: PropTypes.string,
  TitleTag: PropTypes.string,
  title: PropTypes.string,
  href: PropTypes.string,
  views: PropTypes.number,
  description: PropTypes.string,
  htmlDescription: PropTypes.string,
  company: PropTypes.string,
  location: PropTypes.string,
  hours: PropTypes.string,
  createdAt: PropTypes.string,
  createdAtPrefix: PropTypes.string,
  category: PropTypes.string,
  educationLevel: PropTypes.string,
  cta: PropTypes.string,
  note: PropTypes.string,
  image: PropTypes.string,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  range: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  test: PropTypes.string,
  hidden: PropTypes.bool,
  showImagePlaceholder: PropTypes.bool,
  containerRef: PropTypes.func,
};

export default Card;
