import PropTypes from 'prop-types';
import ActiveLink from 'components/ActiveLink';
import { catchInternalUrl } from 'utils';

const wrapInLink = (child, Tag, href, as, noLink) => {
  if (Tag === 'a' && (href || as) && catchInternalUrl(href)) {
    return (
      <ActiveLink href={href} as={as} noLink={noLink}>
        {child}
      </ActiveLink>
    );
  }
  return child;
};

const Button = ({
  as,
  extraClasses,
  href,
  Icon,
  IconLeft,
  IconRight,
  label,
  rawLabel = false,
  Tag = 'button',
  type = 'button',
  hideLabel = false,
  test,
  noLink,
  ...attributes
}) => {
  const className = [
    'c-button',
    hideLabel ? 'c-button--icon-only' : '',
    extraClasses || '',
  ]
    .filter(c => !!c)
    .join(' ');

  const buttonType = !href && Tag === 'button' && !!type ? type : null;
  const ButtonTag = href ? 'a' : Tag;

  // for developer's comfort
  const RightIcon = IconRight || Icon;
  const rel = !!href && !catchInternalUrl(href) ? 'noopener' : null;

  return wrapInLink(
    <ButtonTag
      className={className}
      type={buttonType}
      data-cy={test}
      href={href}
      rel={rel}
      {...attributes}
    >
      <span className="c-button__inner">
        {!!IconLeft && (
          <span
            className="c-button__icon-container c-button__icon-container--left"
            aria-hidden="true"
          >
            <IconLeft className="c-button__icon c-button__icon--left" />
          </span>
        )}

        {!rawLabel && (
          <span
            className={`c-button__label ${
              hideLabel ? 'u-visually-hidden' : ''
            }`.trim()}
          >
            {label}
          </span>
        )}

        {!!rawLabel && (
          <span
            className={`c-button__label ${
              hideLabel ? 'u-visually-hidden' : ''
            }`.trim()}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: label }}
          />
        )}

        {!!RightIcon && (
          <span
            className="c-button__icon-container c-button__icon-container--right"
            aria-hidden="true"
          >
            <RightIcon className="c-button__icon c-button__icon--right" />
          </span>
        )}
      </span>
    </ButtonTag>,
    Tag,
    href,
    as,
    noLink
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  rawLabel: PropTypes.bool,
  as: PropTypes.string,
  extraClasses: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  Icon: PropTypes.func,
  IconLeft: PropTypes.func,
  IconRight: PropTypes.func,
  Tag: PropTypes.string,
  type: PropTypes.string,
  test: PropTypes.string,
  hideLabel: PropTypes.bool,
  noLink: PropTypes.bool,
  attributes: PropTypes.object,
};

export default Button;
