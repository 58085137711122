import PropTypes from 'prop-types';
import FooterTop from '@organisms/Footer/footer-top';
import FooterBottom from '@organisms/Footer/footer-bottom';
import { FOOTER_ID } from 'lib/constants';

const Footer = ({ extraClasses, showItems = false }) => (
  <footer id={FOOTER_ID} className={`c-footer ${extraClasses || ''}`.trim()}>
    <div className="c-footer__content o-retain o-retain--wall">
      {!!showItems && <FooterTop />}
      <FooterBottom />
    </div>
  </footer>
);

export default Footer;

Footer.propTypes = {
  extraClasses: PropTypes.string,
  showItems: PropTypes.bool,
};
