export const VIEW_MODES = {
  SPLIT: 'split',
  LIST: 'list',
};

export const FOOTER_ID = 'dbs-footer';

export const ROUTES = {
  vacancies: '/vacatures',
  uploadCv: '/cv-uploaden',
  addVacancy: '/vacature-plaatsen',
};
