import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import { ApolloProvider } from '@apollo/client';
import { appWithTranslation } from 'next-i18next';
import { useApollo } from 'lib/apolloClient';
import { GTMPageView } from 'lib/gtm';
import { AuthProvider } from 'utils/useAuth';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import NProgress from '@atoms/NProgress/NProgress';
import 'assets/scss/app.scss';
import Head from 'next/head';
import Favicons from '@atoms/Favicons/Favicons';

import { Mulish } from 'next/font/google';

const mulish = Mulish({ subsets: ['latin'] });

// This is the solution to prevent the "flickering" of colors when setting theme color.
// This injects a script at the top of the body that will block rendering
// and set the root css values first. Read more: https://www.joshwcomeau.com/react/dark-mode/
const ThemeScriptTag = ({ font, brandColor, buttonTextColor }) => {
  const codeToRunOnClient = `
      (function() {
        const root = document.documentElement;

        if ('${font}' !== 'undefined'
          || '${brandColor}' !== 'undefined'
          || '${buttonTextColor}' !== 'undefined') {
          document.body.classList.add('whitelabel-theme');
        }

        if ('${font}' !== 'undefined') {
          root.style.setProperty(
            '--font-family-base', '${font}'
          );
        }

        if ('${brandColor}' !== 'undefined') {
          root.style.setProperty(
            '--color-brand', '${brandColor}'
          );
          root.style.setProperty(
            '--color-brand-secondary', '${brandColor}'
          );
        }

        if ('${buttonTextColor}' !== 'undefined') {
          root.style.setProperty(
            '--color-button-text', '${buttonTextColor}'
          );
        }
      })()
    `;
  // eslint-disable-next-line react/no-danger
  return <script dangerouslySetInnerHTML={{ __html: codeToRunOnClient }} />;
};

const App = ({
  Component,
  pageProps: { user, websiteSettings, ...pageProps },
}) => {
  const apolloClient = useApollo(pageProps);
  // GTM
  useEffect(() => {
    const handleRouteChange = url => GTMPageView(url);
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      {websiteSettings && ThemeScriptTag(websiteSettings)}
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        {websiteSettings?.favicon ? (
          <link
            rel="shortcut icon"
            href={websiteSettings?.favicon.contentUrl}
          />
        ) : (
          <Favicons />
        )}
        <title>deBanensite.nl</title>

        <style>{`
          :root {
            --font-family-base: ${mulish.style.fontFamily};
            --font-family-heading: ${mulish.style.fontFamily};
          }
        `}</style>
      </Head>

      <NProgress />

      <ErrorBoundary>
        {/* Pass the user when server side retrieving the user detail */}
        <AuthProvider user={user}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Component user={user} {...pageProps} />
        </AuthProvider>
      </ErrorBoundary>
    </ApolloProvider>
  );
};

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object,
};

export default appWithTranslation(App);
