import { useEffect, useState } from 'react';
import { EMPLOYER } from 'lib/queries';
import { useQuery } from '@apollo/client';

export default function useCompanyBranch({ slug = '' } = {}) {
  const { loading, error, data } = useQuery(EMPLOYER, {
    variables: {
      slug,
    },
  });
  const [companyBranch, setCompanyBranch] = useState();

  useEffect(() => {
    const companyBranchNode = data?.companyBranches?.edges[0]?.node;
    if (companyBranchNode) return setCompanyBranch(companyBranchNode);
    return setCompanyBranch(null);
  }, [data]);

  return {
    loading,
    error,
    companyBranch,
  };
}
