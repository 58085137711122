import { CURRENT_USER_QUERY } from './currentUserQuery';
import { EDUCATION_LEVELS } from './educationLevels';
import { EMPLOYER_VACANCIES } from './employerVacancies';
import { EMPLOYER } from './employer';
import { EMPLOYER_WEBSITE } from './employerWebsite';
import { EXTRA_EMPLOYER_DATA } from './extraEmployerData';
import { EXTRA_VACANCIES_DATA } from './extraVacanciesData';
import { GET_VACANCY_BY_REMOTE_ID } from './getVacancyByRemoteId';
import { HAS_APPLIED_VACANCY } from './hasAppliedVacancy';
import { HOMEPAGE_JOB_POSTINGS } from './homepageJobPostings';
import { SINGLE_JOB_POSTING } from './singleJobPosting';
import { USER_EXISTS } from './userExists';
import { VACANCY_ALERT } from './vacancyAlert';

export {
  CURRENT_USER_QUERY,
  EDUCATION_LEVELS,
  EMPLOYER_VACANCIES,
  EMPLOYER,
  EMPLOYER_WEBSITE,
  EXTRA_EMPLOYER_DATA,
  EXTRA_VACANCIES_DATA,
  GET_VACANCY_BY_REMOTE_ID,
  HAS_APPLIED_VACANCY,
  HOMEPAGE_JOB_POSTINGS,
  SINGLE_JOB_POSTING,
  USER_EXISTS,
  VACANCY_ALERT,
};
