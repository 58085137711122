import Link from 'next/link';

const FooterBottom = () => (
  <div className="c-footer__bottom u-text-small">
    &copy; {new Date().getFullYear()} - <Link href="/">deBanenSite.nl</Link>{' '}
    &reg;
  </div>
);

export default FooterBottom;
