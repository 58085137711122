import { CREATE_APPLICANT } from './createApplicant';
import { UPDATE_APPLICANT } from './updateApplicant';
import { DELETE_APPLICANT } from './deleteApplicant';
import { CREATE_CV } from './createCv';
import { UPDATE_CV } from './updateCv';
import { DELETE_ALL_CVS_APPLICANT } from './deleteAllCvsApplicant';
import { CREATE_APPLICATION } from './createApplication';
import { UPLOAD_MEDIA_OBJECT } from './uploadMediaObject';
import { CREATE_VACANCY_ALERT } from './createVacancyAlert';
import { DELETE_VACANCY_ALERT } from './deleteVacancyAlert';
import { DELETE_ALL_VACANCY_ALERTS } from './deleteAllVacancyAlerts';
import { ADD_VIEW_TO_VACANCY } from './addViewToVacancy';

export {
  CREATE_APPLICANT,
  UPDATE_APPLICANT,
  DELETE_APPLICANT,
  CREATE_CV,
  UPDATE_CV,
  DELETE_ALL_CVS_APPLICANT,
  CREATE_APPLICATION,
  UPLOAD_MEDIA_OBJECT,
  CREATE_VACANCY_ALERT,
  DELETE_VACANCY_ALERT,
  DELETE_ALL_VACANCY_ALERTS,
  ADD_VIEW_TO_VACANCY,
};
