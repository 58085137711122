import { useTranslation } from 'next-i18next';
import InstagramIcon from 'svg/instagram.svg';
import LinkedInIcon from 'svg/linkedin.svg';
import FacebookIcon from 'svg/facebook.svg';
import Link from 'next/link';

const FooterTop = () => {
  const { t } = useTranslation();

  const urlLists = [
    {
      title: t('footer.deBanenSite'),
      items: [
        {
          label: t('footer.about'),
          url: '/over-debanensite',
        },
        {
          label: t('footer.browse'),
          url: '/bladeren',
        },
        {
          label: t('footer.terms'),
          url: '/algemene-voorwaarden',
        },
        {
          label: t('footer.disclaimer'),
          url: '/disclaimer',
        },
        {
          label: t('footer.privacy'),
          url: '/privacy',
        },
      ],
    },
    {
      title: t('footer.forJobSeekers'),
      hiddenOnMobile: true,
      items: [
        {
          label: t('footer.faq'),
          url: '/faq',
        },
        {
          label: t('footer.jobSeekers'),
          url: '/werkzoekenden',
        },
        {
          label: t('footer.findVacancy'),
          url: '/vacatures',
        },
        {
          label: t('footer.findEmployer'),
          url: '/werkgevers',
        },
      ],
    },
    {
      title: t('footer.forEmployers'),
      hiddenOnMobile: true,
      items: [
        {
          label: t('footer.postVacancy'),
          url: '/vacature-plaatsen',
        },
        {
          label: t('footer.login'),
          url: '/login',
        },
        {
          label: t('footer.cvDatabase'),
          url: `${process.env.EMPLOYER_FRONTEND_ENDPOINT}/cvs`,
        },
        {
          label: t('footer.references'),
          url: '/referenties',
        },
      ],
    },
  ];

  const contact = {
    title: t('footer.contact'),
    items: [
      {
        label: t('footer.phoneNumber'),
        url: `tel:${t('data.phoneNumber').replace(/\D/g, '')}`,
      },
      {
        label: t('footer.email'),
        url: `mailto:${t('data.email')}?subject=${t('footer.emailSubject')}`,
      },
    ],
  };

  const socials = [
    {
      name: 'instagram',
      icon: InstagramIcon,
    },
    {
      name: 'linkedIn',
      icon: LinkedInIcon,
    },
    {
      name: 'facebook',
      icon: FacebookIcon,
    },
  ];

  const structuredData = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'deBanenSite.nl',
    url: 'https://debanensite.nl',
    telephone: t('data.phoneNumber'),
    email: t('data.email'),
    fax: t('data.fax'),
    address: {
      '@type': 'PostalAddress',
      streetAddress: t('data.address.streetAddress'),
      addressLocality: t('data.address.addressLocality'),
      postalCode: t('data.address.postalCode'),
      addressCountry: t('data.address.addressCountry'),
    },
  });

  return (
    <div className="o-layout o-layout--gutter-base u-margin-bottom-base">
      {urlLists.map(list => (
        <div
          className={`o-layout__cell u-fraction--6of12@from-md u-fraction--3of12@from-lg ${
            list.hiddenOnMobile ? 'u-hidden@until-md' : ''
          }`.trim()}
          key={`footer-${list.title}`}
        >
          <h3 className="c-footer__title">{list.title}</h3>
          <ul className="c-footer__list u-margin-bottom-none@from-lg">
            {list.items.map(item => (
              <li
                className="c-footer__list-item"
                key={`footer-${list.title}-${item.label}`}
              >
                <Link href={item.url}>{item.label}</Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
      <div className="o-layout__cell u-fraction--6of12@from-md u-fraction--3of12@from-lg">
        <h3 className="c-footer__title">{contact.title}</h3>
        <ul className="c-footer__list">
          {contact.items.map(item => (
            <li
              className="c-footer__list-item"
              key={`footer-contact-${item.url}`}
            >
              <a href={item.url} className="o-layout">
                {item.label}
              </a>
            </li>
          ))}
        </ul>
        <ul className="o-layout o-layout--gutter-small">
          {socials.map(social => {
            const SocialIcon = social.icon;

            return (
              <li
                className="o-layout__cell o-layout__cell--fit"
                key={`footer-${social.name}`}
              >
                <a
                  href={t(`data.${social.name}`)}
                  className="c-footer__social"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SocialIcon />
                  <span className="u-visually-hidden">
                    {t(`footer.${social.name}`)}
                  </span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: structuredData }}
      />
    </div>
  );
};

export default FooterTop;
