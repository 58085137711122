import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { USER_EXISTS } from 'lib/queries';

export default function useCheckEmail(user) {
  const [emailIsValid, setEmailIsValid] = useState();
  const [emailAlreadyInUse, setEmailAlreadyInUse] = useState(false);
  // Query to check if given email address is already in use
  const [checkIfUserExistsQuery, { loading: checkingIfUserExists }] =
    useLazyQuery(USER_EXISTS, {
      onCompleted() {
        setEmailIsValid(true);
        setEmailAlreadyInUse(false);
      },
      onError() {
        setEmailIsValid(false);
        setEmailAlreadyInUse(true);
      },
    });

  const checkIfUserExists = email => {
    // If the entered email is the same as the registered email, the email is valid
    if (user?.email === email) {
      setEmailAlreadyInUse(false);
      return setEmailIsValid(true);
    }
    checkIfUserExistsQuery({
      variables: {
        email,
      },
    });
  };

  useEffect(() => {
    if (user) return setEmailIsValid(true);
  }, [user]);

  return {
    checkIfUserExists,
    checkingIfUserExists,
    emailIsValid,
    emailAlreadyInUse,
  };
}
